import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import 'dropzone/dist/dropzone.css'; // Import Dropzone CSS
import Icon from 'components/Icon';

const MyDropzone = ({ gettingfilefromdropzone }) => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [firstscreen, setFirstscreen] = useState(false);
  const [audioFileChecks, setAudioFileChecks] = useState({});

  const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25 MB in bytes

  const allowedTypes = [
    'text/x-c',
    'text/x-c++src',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/html',
    'text/x-java-source',
    'application/json',
    'text/markdown',
    'application/pdf',
    'application/x-httpd-php',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/x-python',
    'application/x-ruby',
    'application/x-tex',
    'text/plain',
    'text/css',
    'application/javascript',
    'application/x-sh',
    'application/typescript',
    'application/vnd.ms-excel', // Excel (XLS)
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel (XLSX)
    'text/csv', // CSV
    'audio/mpeg', // mp3
    'video/mp4', // mp4
    'video/mpeg', // mpeg
    'audio/mp4', // m4a
    'audio/wav' // wav
  ];

  const audiofilestype = [
    'audio/mpeg', // mp3
    'video/mp4', // mp4
    'video/mpeg', // mpeg
    'audio/mp4', // m4a
    'audio/wav' // wav
  ];

  useEffect(() => {
    const storedFiles = localStorage.getItem('uploadedFiles');
    if (storedFiles) {
      setFiles(JSON.parse(storedFiles));
    }
  }, []);

  const saveFilesToStorage = updatedFiles => {
    localStorage.setItem('uploadedFiles', JSON.stringify(updatedFiles));
    setLoader(false);
  };

  const onDrop = useCallback(acceptedFiles => {
    const oversizedFiles = acceptedFiles.filter(file => file.size > MAX_FILE_SIZE);
    const validFiles = acceptedFiles.filter(file => file.size <= MAX_FILE_SIZE && allowedTypes.includes(file.type));
    let errorMessages = ''; // Initialize error message accumulator

    // Check for oversized files
    if (oversizedFiles.length > 0) {
      const oversizedFileNames = oversizedFiles.map(file => file.name).join(', ');
      errorMessages += `<p style="color:red;">File "${oversizedFileNames}" is larger than 25 MB and won't be uploaded.<br></br></p>`

    }

    // Check for invalid file types
    if (validFiles.length !== acceptedFiles.length) {
      const invalidFiles = acceptedFiles.filter(file => !allowedTypes.includes(file.type));
      const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
      if (invalidFileNames) {
        errorMessages +=`<p style="color:red;">Only specific file types are allowed. Invalid files: <br></br>${invalidFileNames}</p>`;
      }
    }

    // Set the error message if any errors occurred
    if (errorMessages !== '') {
      setErrorMessage(errorMessages);
      return; // Stop processing further if any errors occurred
    }

    // Proceed with valid files
    const updatedFiles = validFiles.map(file => ({
      file,
      progress: 0,
      completed: false
    }));

    setFiles([...files, ...updatedFiles]);
    saveFilesToStorage([...files, ...updatedFiles]);

    if (errorMessage !== '') {
      setErrorMessage('');
      setFirstscreen(true);
    } else {
      setFirstscreen(true);
    }

    // Progress simulation
    const interval = setInterval(() => {
      setFiles(prevFiles =>
        prevFiles.map(prevFile => {
          const updatedFile = updatedFiles.find(file => file.file === prevFile.file);
          if (updatedFile) {
            if (prevFile.progress < 100) {
              setLoader(true);
              setFirstscreen(true);
              updatedFile.progress += 10;
            } else if (prevFile.progress >= 100) {
              updatedFile.progress = 100;
              updatedFile.completed = true;
              clearInterval(interval);
            }
          }
          return updatedFile || prevFile;
        })
      );
      saveFilesToStorage(files);
    }, 1000);

    return () => clearInterval(interval);
  }, [files, saveFilesToStorage]);


  const handlesave = e => {
    e.preventDefault();
    const filesWithCheckboxes = files.map(file => ({
      ...file,
      isChecked: audioFileChecks[file.file.name] || true
    }));
    gettingfilefromdropzone(filesWithCheckboxes);
    setLoader(true);
    localStorage.removeItem('uploadedFiles');
  };

  const handleDelete = fileToDelete => {
    const updatedFiles = files.filter(file => file.file !== fileToDelete);
    setFiles(updatedFiles);
    saveFilesToStorage(updatedFiles);
    if (updatedFiles.length < 1) {
      setFirstscreen(false);
    }
  };

  const handleCheckboxChange = (fileName, isChecked) => {
    setAudioFileChecks({
      ...audioFileChecks,
      [fileName]: isChecked
    });
  };

  const restart = () => {
    setFiles([]);
    saveFilesToStorage([]);
    setFirstscreen(false);
  };

  return (
    <div className='maindiv'>
      <Dropzone onDrop={onDrop} accept={allowedTypes.join(',')}>
        {({ getRootProps, getInputProps }) => (
          <>
            {firstscreen === false && <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              {files.length <= 0 && (
                <div className="text-center" id="removetext">
                  <p>Drag & drop files here, or click to select files</p>
                  <button className="uploadbutton">Upload Files</button>
                </div>
              )}
              {errorMessage && (
                <div className="error-message text-center">
                  <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                </div>
              )}
            </div>}
          </>
        )}
      </Dropzone>
      <div className="text-right position-absolute" style={{ top: 0, right: "72px" }}>
        {files.length > 0 && (
          <>
            <button onClick={restart} className="px-2 mx-2 cancelbutton">
              Cancel
            </button>
            {loader ? <button className="uploadbutton">
              wait...
            </button>
              : <button onClick={handlesave} className="uploadbutton">
                Continue
              </button>}
          </>
        )}
      </div>
      <div className="file-list">
        {files.map((uploadedFile, index) => (
          <div key={index} className="file-item">
            <p className="ellipsetext">{uploadedFile.file.name}</p>
            <div className='deleterow'>
              <p style={{ margin: "10px 0px" }}>{parseFloat(uploadedFile.file.size / (1024 * 1024)).toFixed(2)} MB</p>
              <p className='px-2' onClick={() => handleDelete(uploadedFile.file)}> {uploadedFile.completed && <Icon id="delete" />}</p>
            </div>
            {!uploadedFile.completed && (
              <progress className="progress-z" value={uploadedFile.progress} max="100" />
            )}
            {uploadedFile.completed && (
              <p className="success-message"></p>
            )}
            {!uploadedFile.completed && <button onClick={() => handleDelete(uploadedFile.file)} className="crossicon-z delete-button">
              X
            </button>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyDropzone;
