import React, { useState, useEffect } from 'react';

const LoadingDots = ({ speed = 300 }) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const dotSequence = ['', '.', '..', '...'];
    let index = 0;

    const intervalId = setInterval(() => {
      setDots(dotSequence[index]);
      index = (index + 1) % dotSequence.length;
    }, speed);

    return () => clearInterval(intervalId);
  }, [speed]);

  return <span style={{ width: '20px', display: 'inline-block' }}>{dots}</span>;
};

export default LoadingDots;
