import React, { useEffect, useRef, useState, useCallback } from "react";
import "./styles/main.css";
import ChatInput from "./components/ChatInput";
import Icon from "components/Icon";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";

import MyDropzone from './components/MyDropzone'; // Import your Dropzone component
import ErrorHandlingModal from "components/ErrorHandlingModal";
import filesubnmitted from "./folder-access.ico"
const Chat = ({ match, history }) => {
  const { users, setUserAsUnread, addNewMessage, contextuploadFilefrominput, showLeftSidebar, hideLeftSidebar, isActiveSide, agencyInfo, errorType, setErrorType, errorMsg } = useUsersContext();
  const userId = match.params.id;

  let user = users.filter((user) => user.id === userId)[0];
  const lastMsgRef = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [footerHeight, setFooterHeight] = useState('64px');
  const [goBottomBtn, setGoBottomBtn] = useState('120px');
  const [alertModel, setAlertModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [modelContent, setModelContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(errorType)
  useEffect(() => {
    if (!user) {
      const redirectUrl = `/`;
      history.push(redirectUrl);
    } else {
      scrollToLastMsg();
      setUserAsUnread(user.id);
    }
  }, []);

  useEffect(() => {
    setIsModalOpen(errorType)
  }, [errorType])

  useEffect(() => {
    if (user) {
      scrollToLastMsg();
    }
  }, [users, user]);

  const justCloseIt = () => {
    setErrorType(false);
  }

  useEffect(() => {
  }, [isActiveSide])

  useEffect(() => {
    if (footerHeight) {
      const newHeight = parseInt(footerHeight.replace(/\D/g, '')) + 56 + 'px';
      setGoBottomBtn(newHeight)
    }
  }, [footerHeight])

  const closeSidebar = () => {
    setShowSearchSidebar(false)
    showLeftSidebar();
  }

  const scrollToLastMsg = () => {
    if (lastMsgRef.current) {
      // alert("diyh");
      lastMsgRef.current.scrollIntoView();
    }
  };

  const submitNewMessage = (prompt = newMessage, type = "savetodb") => {
    scrollToLastMsg();
    addNewMessage(user.id, newMessage, prompt, type);
    setNewMessage("");
    scrollToLastMsg();
    setTimeout(() => {
      document.getElementById('gotovie')?.scrollIntoView({ behavior: 'smooth' });
    }, 2000);
  };
  const gettingfilefromdropzone = async (files) => {
    var response = await contextuploadFilefrominput(user.id, files);
    if (response === "uploaded") {
      setModelContent("file Submitted ..!")
      setAlertModel(false);
      setSuccessModel(true)
    } else {
    }
  }
  const uploadFilefrominput = async (stateget) => {
    setAlertModel(stateget);
  }
  return (
    <div className="chat">
      <div className="chat__body">
        <div className="chat__bg"></div>
        <div className="chat__content">
          {user && (
            <Convo
              userId={userId}
              lastMsgRef={lastMsgRef}
              messages={user.messages}
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              submitNewMessage={submitNewMessage}
              agencyInfo={agencyInfo}
            />
          )}
        </div>
        <footer className="chat__footer">
          <button
            style={{ bottom: goBottomBtn }}
            className="chat__scroll-btn"
            aria-label="scroll down"
            onClick={scrollToLastMsg}
            id="trigerafterreguest"
          >
            <Icon id="downArrow" />
          </button>

          <ChatInput
            showEmojis={showEmojis}
            setShowEmojis={setShowEmojis}
            showAttach={showAttach}
            setShowAttach={setShowAttach}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            submitNewMessage={submitNewMessage}
            setFooterHeight={setFooterHeight}
            footerHeight={footerHeight}
            agencyInfo={agencyInfo}
            chatId={userId}
          />
          <p className="text-center text-gray">Your data is not used to train our models. AI can make mistakes please check your answer.</p>
        </footer>
        {alertModel && <>
          <div className="modal-zz" onClick={() => setAlertModel(false)}>
            <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
              <p
                style={{
                  position: "absolute",
                  right: 5,
                  width: "12px",
                  top: "10px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => setAlertModel(false)}
              >
              </p>

              <MyDropzone gettingfilefromdropzone={gettingfilefromdropzone} />
            </div>
          </div>
        </>}
        {successModel && <>
          <div className="modal-zz" onClick={() => setSuccessModel(false)}>
            <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
              <p
                style={{
                  position: "absolute",
                  right: 5,
                  width: "12px",
                  top: "10px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => setSuccessModel(false)}
              >
                X
              </p>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src={filesubnmitted} width={"200px"} height={"200px"} />

                <h1 className="text-center">Files are Submitted!</h1>

              </div>
            </div>
          </div>
        </>}
        <ErrorHandlingModal isModalOpen={isModalOpen} justCloseIt={justCloseIt} errorMessage={errorMsg} type={errorType} />
      </div>
    </div>
  );
};

export default Chat;
