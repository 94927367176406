import { useUsersContext } from 'context/usersContext';
import MyDropzone from 'pages/Chat/components/MyDropzone';
import React, { useState } from 'react'
import filesubnmitted from "pages/Chat/folder-access.ico"
import Icon from 'components/Icon';
import { useHistory } from 'react-router-dom';

function Index({ classname, chatId }) {
  const { agencyInfo, contextuploadFilefrominput, SSO, enableChat, setUserAsUnread, refresh, justToSaveMsg, showToast, ChatSubmit } = useUsersContext();
  const history = useHistory();
  const [alertModel, setAlertModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [modelContent, setModelContent] = useState('');
  const gettingfilefromdropzone = async (files) => {

    var response = await contextuploadFilefrominput(files, chatId);
    if (response.status === "uploaded") {
      if (response?.transcript !== 'otherFile' && (chatId === '' || chatId === undefined)) {
        const payload = {
          locationId: SSO?.id,
          username: SSO?.first_name,
        };
        enableChat();

        const temp = await ChatSubmit(payload, true);

        if (!temp?.response) {
          refresh(temp);

          const chatUrl = `/chat/${temp.id}`;
          setUserAsUnread(temp.id);
          justToSaveMsg(response?.transcript, temp.id)
          history.push(chatUrl);
        }
        justToSaveMsg(response?.transcript, chatId)
      }
      setModelContent("file Submitted ..!")
      setAlertModel(false);
      setSuccessModel(true)
    } else {
      showToast('Something went wrong!')
    }
  }

  return (
    <>
      {/* alerts */}
      {alertModel && <>
        <div className="modal-zz" onClick={() => setAlertModel(false)}>
          <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 5,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={() => setAlertModel(false)}
            >
              X
            </p>

            <MyDropzone gettingfilefromdropzone={gettingfilefromdropzone} />
          </div>
        </div>
      </>}
      {successModel && <>
        <div className="modal-zz" onClick={() => setSuccessModel(false)}>
          <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 5,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={() => setSuccessModel(false)}
            >
              X
            </p>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img src={filesubnmitted} width={"200px"} height={"200px"} />

              <h1 className="text-center">Files are Submitted!</h1>

            </div>
          </div>
        </div>
      </>}
      <button onClick={() => setAlertModel(true)} aria-label="Send message" className={classname}   >
        <div className="hover-text">
          <Icon id="vectorfile" className="chat__input-icon" />
          {agencyInfo?.Customization?.uploadicone_text &&
            <span className="tooltip-text" id="uploadtext">
              {agencyInfo?.Customization?.uploadicone_text}</span>
          } </div>

      </button>
    </>
  )
}

export default Index
