import React from 'react';
import './styles.css';

const Toast = ({ message, styles, onClose }) => {
	return (
		<div className="toast" style={styles ? styles : { backgroundColor: '#333' }} onClick={onClose}>
			{message}
		</div>
	);
};

export default Toast;
