import React, { useState } from "react";
import Icon from "components/Icon";
import "./index.css";
import localforage from "localforage";
const BASE_URL = `https://apichat.maybetech.com/api/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const ErrorHandlingModal = ({ isModalOpen, justCloseIt, errorMessage, type }) => {
  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <p className="modalText">
          {errorMessage}
        </p>
        <div className="modalBtnGroup">
          <button type="button" className="agreeBtn" onClick={justCloseIt}>
            Got It
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorHandlingModal;
