import React from "react";
import CategoryAccordion from "./CategoryAccordion";
import { useUsersContext } from "context/usersContext";

const Prompts = ({ userId, promptsData, type, favourites, getFavourites, refreshPrompts, agencyConfig }) => {
	const { ChatSubmit } = useUsersContext();
	const catIds = agencyConfig?.category_ids
	const subCatIds = agencyConfig?.sub_category_ids
	const promptIds = agencyConfig?.prompt_ids

	return (
		<>
			<div className="chat-sidebar__search-results p-0">
				{/* <h2 className="promptsHeading">Explore Prompts</h2> */}
				{promptsData && (
					<CategoryAccordion
						promptsCategories={promptsData?.prompt_category
							.filter(obj => type === "general" ? ['super-admin', 'agency-admin'].includes(obj.type) : !['super-admin', 'agency-admin'].includes(obj.type))
							.filter(category => !catIds?.includes(category._id))}
						promptsSubCategories={promptsData?.prompt_sub_category
							.filter(obj => type === "general" ? ['super-admin', 'agency-admin'].includes(obj.type) : !['super-admin', 'agency-admin'].includes(obj.type))
							.filter(category => !subCatIds?.includes(category._id))}
						prompts={promptsData?.prompt
							.filter(obj => type === "general" ? ['super-admin', 'agency-admin'].includes(obj.type) : !['super-admin', 'agency-admin'].includes(obj.type))
							.filter(prompt => !promptIds?.includes(prompt._id))}
						userId={userId}
						ChatSubmit={ChatSubmit}
						favourites={favourites}
						getFavourites={getFavourites}
						promptType={type}
						allPromptsData={promptsData}
						refreshPrompts={refreshPrompts}
					/>
				)}
			</div>
		</>
	);
};

export default Prompts;
