import Icon from "components/Icon";
import React, { useEffect, useRef, useState } from "react";
import SharedPrompt from "components/Sidebar/SharedPrompt";

//  accordionitem component
const AccordionItem = ({ promptsData, contacts, onDelete, isOpen, onClick, category, index, favourites, getFavourites }) => {
    const contentHeight = useRef();
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    return (
        <div className={`chatPrompt catWrapper ${isOpen ? "activeWrapper" : ""}`}>
            <button
                className={`question-container ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <p className="question-content parentCategory">
                    {category}
                </p>
                {isOpen ?
                    <Icon id="upArrow" className="chat__input-icon" /> :
                    <Icon id="downArrow" className="chat__input-icon" />}
                {/* <RiArrowDropDownLine className={`arrow ${isOpen ? "active" : ""}`} /> */}
            </button>

            <div
                ref={contentHeight}
                className="answer-container subCatAnswer"
                style={
                    isOpen
                        ? { height: "fit-content", margin: 0, maxHeight: '400px', minHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }
                        : { height: "0px" }
                }
            >
                <div className="answer-content">
                    {contacts?.map((contact, index) => (
                        <SharedPrompt key={index} contact={contact} onDelete={onDelete}
                            index={index}
                            openDropdownIndex={openDropdownIndex}
                            setOpenDropdownIndex={setOpenDropdownIndex}
                            favourites={favourites}
                            getFavourites={getFavourites}
                            promptsData={promptsData}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const SharedPromptAccordions = ({ promptsData, contacts, onDelete, categories, favourites, getFavourites }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        const uncategorizedIndex = categories.findIndex(item => item.name === "Uncategorised");
        if (uncategorizedIndex !== -1) {
            setActiveIndex(uncategorizedIndex);
        }
    }, [categories]);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {categories?.map((item, index) => (
                <>
                    <AccordionItem
                        key={index}
                        index={index}
                        contacts={contacts}
                        onDelete={onDelete}
                        isOpen={activeIndex === index}
                        onClick={() => handleItemClick(index)}
                        category={item.name}
                        favourites={favourites}
                        getFavourites={getFavourites}
                        promptsData={promptsData}
                    />
                </>
            ))}
        </>
    );
};

export default SharedPromptAccordions;
