import Icon from "components/Icon";
import React, { useRef, useState } from "react";
import Contact from "components/Sidebar/Contact";
import EditChatCategoryModal from "components/EditChatCategoryModal";
import axios from "axios";
import env from "config";
import DeleteChatModal from "components/DeleteChatModal";
//  accordionitem component
const AccordionItem = ({ addChatCategoryHandle, contacts, onDelete, isOpen, onClick, category, favourites, getFavourites, chatCategories, handleRefresh }) => {
    const contentHeight = useRef();
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [isOpenAddChatCategoryModal, setIsOpenAddChatCategoryModal] = useState(false);

    const justCloseAddChatCategoryModal = () => {
        setIsOpenAddChatCategoryModal(false);
    }

    const handleEditCategory = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenAddChatCategoryModal(true)
    }
    const [IsopenDeleteModel, setIsopenDeleteModel] = useState(false);

    const handleDeleteCategory = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsopenDeleteModel(true)
    }
    const BASE_URL = `${env.API_URL}/v1`;
    const deletecat = async (category) => {
        await axios
            .post(BASE_URL + "/chat/deletecategories", category)
            .then(async function (response) {
                handleRefresh();
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>

            <div className={`chatPrompt catWrapper ${isOpen ? "activeWrapper" : ""}`}>
                <button
                    className={`question-container ${isOpen ? "active" : ""}`}
                    onClick={onClick}
                >
                    <p className="question-content parentCategory">
                        {category.name == 'Favourites' ? (<span style={{ marginRight: '10px', verticalAlign: 'text-bottom' }}><Icon id="filledStar" /></span>) : ''}
                        {category.name}
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>

                        {category.name != "Uncategorised" ?
                            <>
                                <p style={{ fontSize: '14px', fontWeight: 'bold', color: "red", marginRight: "10px" }}
                                    onClick={(e) => handleDeleteCategory(e)}>
                                    Delete
                                </p>
                                <p className="question-content parentCategory" onClick={(e) => handleEditCategory(e)}>
                                    Edit
                                </p>
                            </>
                            : <></>}
                        {isOpen ?
                            <Icon id="upArrow" className="chat__input-icon" /> :
                            <Icon id="downArrow" className="chat__input-icon" />}
                    </div>
                </button>

                <div
                    ref={contentHeight}
                    className="answer-container subCatAnswer"
                    style={
                        isOpen
                            ? { height: "fit-content", margin: 0, maxHeight: '400px', minHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }
                            : { height: "0px" }
                    }
                >
                    <div className="answer-content">
                        {contacts?.map((contact, index) => (
                            <>
                                {category._id === contact.category ? (
                                    <Contact key={index} contact={contact} onDelete={onDelete}
                                        index={index}
                                        openDropdownIndex={openDropdownIndex}
                                        setOpenDropdownIndex={setOpenDropdownIndex}
                                        favourites={favourites}
                                        getFavourites={getFavourites}
                                        chatCategories={chatCategories}
                                        categoryName={category.name}
                                        addChatCategoryHandle={addChatCategoryHandle}
                                    />
                                ) : ''}
                            </>
                        ))}
                    </div>
                </div>
            </div>
            <DeleteChatModal
                isModalOpen={IsopenDeleteModel}
                justCloseIt={() => setIsopenDeleteModel(false)}
                contact={category}
                yesDelete={() => deletecat(category)}
                type="Category"

            />
            <EditChatCategoryModal
                isModalOpen={isOpenAddChatCategoryModal}
                justCloseIt={justCloseAddChatCategoryModal}
                edit_data={category}
                refreshChatCategories={handleRefresh}
            />
        </>
    );
};

const ChatAccordions = ({ addChatCategoryHandle, contacts, onDelete, categories, favourites, getFavourites, handleRefresh }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {categories?.map((item, index) => (
                <>
                    <AccordionItem
                        handleRefresh={handleRefresh}
                        key={index}
                        index={index}
                        contacts={contacts}
                        onDelete={onDelete}
                        isOpen={activeIndex === index}
                        // isOpen={activeIndex === index}
                        onClick={() => handleItemClick(index)}
                        category={item}
                        favourites={favourites}
                        getFavourites={getFavourites}
                        chatCategories={categories}
                        addChatCategoryHandle={addChatCategoryHandle}
                    />
                </>
            ))}
        </>
    );
};

export default ChatAccordions;
