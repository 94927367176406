import React, { useState, useEffect, useRef, useContext } from "react";
import Icon from "components/Icon";
import Uploadfile from "../../../components/uploadFiles"
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import { useUsersContext } from "context/usersContext";

const ChatInput = ({
  showAttach,
  setShowAttach,
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  setFooterHeight,
  footerHeight,
  setGoBottomBtn,
  uploadFilefrominput,
  agencyInfo,
  chatId
}) => {
  const [inputHeight, setInputHeight] = useState("64px");
  const textareaRef = useRef(null);
  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action (new line) when Enter is pressed
      submitNewMessage(newMessage, 'savetodb');
    }
  };

  useEffect(() => {
    const adjustHeight = () => {
      // Reset height to shrink if content is removed
      textareaRef.current.style.height = '64px';

      // Set height based on scrollHeight, but not more than the max height
      const maxHeight = 200; // Set your maximum height here
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
      setFooterHeight(`${newHeight}px`);
    };

    // Adjust height on message change
    adjustHeight();

    // Adjust height on window resize
    window.addEventListener('resize', adjustHeight);
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [newMessage]);
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const { promptsData, getPrompts } = useContext(PromptsContext);

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }
  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }
  return (
    <div className="chat__input-wrapper" style={{ height: footerHeight }}>
      {/* ... other components ... */}
      <p className="promptonchat" onClick={addPromptHandle}>+ Add Prompt</p>

      <AddPromptModal
        isModalOpen={isOpenAddPromptModal}
        categories={promptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
        subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
        justCloseIt={justCloseIt}
        getUpdatePromptsData={getPrompts}

      />
      <div className="pos-rel" style={{ marginBottom: inputHeight }}></div>

      <textarea
        ref={textareaRef}
        className="chat__input"
        placeholder="What can your AI help you with next?"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={detectEnterPress}
        style={{ overflowY: 'auto', position: 'absolute', bottom: "-2px", width: "100%", left: 0, paddingLeft: "50px" }}
      />
      <button aria-label="Send message" onClick={() => submitNewMessage(newMessage, 'savetodb')} className="sendChatBtn">
        <Icon id="sendChat" className="chat__input-icon" />
      </button>

      <Uploadfile classname="filecogChatBtn" chatId={chatId} />
    </div>
  );
};

export default ChatInput;
