import React, { useState } from "react";
import "./style.css";
import { useLocation } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";

function ChatModal({ processing, enableExplore, enableChat, showExploreContent, setIsSettingsPage }) {
  const history = useHistory();
  const { setUserAsUnread, refresh, showToast, SSO, ChatSubmit } = useUsersContext();

  const handleSettingsClose = (e) => {
    e.preventDefault();
    setIsSettingsPage(false);
    history.push('/')
  };

  return (
    <div className="chat-dropdown" style={{ textAlign: 'right' }}>
      <div>
        <span style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'inherit' }}>
          Settings
        </span>
      </div>
      <span style={{ cursor: "pointer", marginLeft: 'auto' }} onClick={(e) => handleSettingsClose(e)}>
        <Icon id="close" />
      </span>
    </div>
  );
}

export default ChatModal;
