import React from "react";
import "./style.css";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";
import UploadFiles from "../../../uploadFiles"
function ChatModal({ processing, enableExplore, enableChat, showExploreContent, chatId }) {
  const history = useHistory();
  const { setUserAsUnread, refresh, showToast, SSO, ChatSubmit } = useUsersContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: SSO?.id,
      username: SSO?.first_name,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
    } else {
      showToast("Something went wrong!");
    }
  };

  return (
    <div className="chat-dropdown" style={{ display: "flex", justifyContent: "space-between" }}>
      <div>

        <button
          className={`btn btn-newChat ${!showExploreContent ? "activeBtn" : ""
            }`}
          // disabled={processing}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={enableChat}
        >
          Chats
        </button>

        <button
          className={`btn btn-explore ${showExploreContent ? "activeBtn" : ""}`}
          aria-label="New chat"
          style={{ width: "115px" }}
          onClick={enableExplore}
        >
          <Icon id="exp_dots" className="exp-dots" />
          Prompts
        </button>

      </div>
      <div style={{ position: "relative" }}>
        <UploadFiles classname="fromsidebar" chatId={chatId} />
        <div
          style={{ cursor: "pointer" }}
          disabled={processing}
          aria-label="New chat"
          onClick={handleSubmit}
        >
          <Icon id="newchatIcon" />
        </div>
      </div>
    </div>

  );
}

export default ChatModal;
