import Icon from "components/Icon";
import React, { useEffect, useState } from "react";

import media from "assets/images/women.jpeg";
import formatTime from "utils/formatTime";
import { useUsersContext } from "context/usersContext";
import maybeIcon from "assets/images/maybe-icon.png";
import axios from "axios";
import env from "config";
import continuepng from "../continue.png";
import tryagainpng from "../tryagain.png"
import { marked } from "marked";
import ContentRenderer from "./ContentRenderer";

const Convo = ({
  lastMsgRef,
  messages: allMessages,
  userId,
  agencyInfo,
  submitNewMessage
}) => {
  const BASE_URL = `${env.API_URL}/v1`;
  const dates = Object.keys(allMessages);
  const { addNewMessage, users, enableChat, SSO, updateMemory, createMemory, showToast } = useUsersContext();
  const activeConvo = users.find((user) => user.id === userId);
  const [tooltipStates, setTooltipStates] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);

  const SubmitPrompt = (user_msg, prompt) => {
    addNewMessage(userId, user_msg, prompt);
    enableChat();
  };
  const copyToClipboard = (content, id) => {
    const htmlContent = marked(content);

    // Create a temporary container for the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px';
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      console.log('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy content: ', err);
    }

    // Clean up by removing the temporary element
    document.body.removeChild(tempDiv);
  };

  const [questions, setQuestions] = useState([])
  const getQuestions = async () => {
    await axios
      .get(BASE_URL + `/chat/getquestions?agency_id=${agencyInfo._id}`).
      then((response) => {
        setQuestions(response.data.data)
      })
      .catch(function (error) { });
  };
  useEffect(() => {
    getQuestions();
  }, [])

  const handleMemory = async (content) => {
    setLoading(true);
    let memory = await updateMemory(SSO?.id, content)
    if (memory.err) {
      memory = await createMemory(SSO?.id, content);
    }
    setLoading(false);
    showToast('Memory updated', 'success');
  }

  return dates?.map((date, dateIndex) => {
    const messages = allMessages[date];
    return (
      <div key={dateIndex}>
        <div className="chat__date-wrapper">
          <span className="chat__date"> {date}</span>
        </div>
        {messages.length == 0 && !activeConvo.fromPrompt ? (
          <div className="noMessagePlaceholder">
            <div className="iconBox">
              <img src={agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_logo : maybeIcon} alt="maybe icon" style={{ height: '100px' }} />
              <p>
                <b style={{ color: "black" }}>
                  {agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_text : 'How can Personalised AI help you today?'}
                </b>
              </p>
            </div>
            <div className="row">
              {questions.length > 0 && questions.map((question, ind) => (
                <div className="col-md-6 pb-30px" key={ind}>
                  <div className="prompt_box" onClick={() =>
                    SubmitPrompt(
                      question.user_message,
                      question.prompt
                    )
                  }>
                    <div className="prompt_text">
                      <b>{question.user_message}</b>
                    </div>
                    <Icon id="send" className="chat__input-icon prompt_icon" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="chat__msg-group">
          {messages?.map((message, msgIndex) => {
            const htmlContent = marked(message.content);
            const assignRef = () =>
              dateIndex === dates.length - 1 && msgIndex === messages.length - 1
                ? lastMsgRef
                : undefined;
            return (
              <>
                {message.image ? (
                  <div className={`chat__msg chat__img-wrapper ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"}`}
                    ref={assignRef()}
                  >
                    <img src={media} alt="" className="chat__img" />
                    <span className="chat__msg-footer">
                      <span>{formatTime(message.time)}</span>
                      {!message.sender && (
                        <Icon
                          id={
                            message?.status === "sent"
                              ? "singleTick"
                              : "doubleTick"
                          }
                          aria-label={message?.status}
                          className={`chat__msg-status-icon ${message?.status === "read"
                            ? "chat__msg-status-icon--blue"
                            : ""
                            }`}
                        />
                      )}
                    </span>
                  </div>
                ) : message.sender ? (
                  <p className="chat__msg chat__msg--rxd" style={{ marginTop: "25px" }} ref={assignRef()}>
                    <ContentRenderer index={msgIndex} htmlContent={htmlContent} />
                    {/* Add the copy button */}
                    <div className="topsettingonresponse d-flex justify-content-between " style={{ width: "96%" }}>
                      <div className="w-50 d-flex justify-content-start">
                        <p className="hovercolor" onClick={() => submitNewMessage("Rewrite the previous response", "notsavetodb")} ><img src={tryagainpng} /> Try Again</p>
                        <p className="hovercolor  px-4" onClick={() => submitNewMessage("Continue", "notsavetodb")} ><img src={continuepng} />Continue</p>
                      </div>
                      <div className="w-50 text-end text-right">
                        <button
                          onMouseEnter={() => setShowTooltip(msgIndex)}
                          onMouseLeave={() => setShowTooltip(null)}
                          onClick={() => copyToClipboard(message.content, msgIndex)}
                          aria-label="Copy message"
                          style={{ marginRight: "10px" }}
                        >
                          <div class="hover-text"><Icon id="copy" />
                            {agencyInfo?.Customization?.copyicon_text &&
                              <span class="tooltip-text" id="copytext">{agencyInfo?.Customization?.copyicon_text}</span>
                            }
                          </div>

                        </button>
                        <button aria-label="Copy message" onClick={() => { handleMemory(message?.content) }}>
                          <div class="hover-text">
                            {loading ? (
                              <Icon id="spinner" />
                            ) : (
                              <Icon id="brainai" />
                            )}
                            <span class="tooltip-text" id="braintext">
                              {agencyInfo?.Customization?.brainicon_text}
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                    {showTooltip === msgIndex && (
                      <div className="chat__tooltip">{tooltipStates[msgIndex] || 'Copy!'}</div>
                    )}
                    <span className="chat__msg-footer">
                      {formatTime(message.time)}
                    </span>
                    <span className="chat__msg-filler"></span>
                  </p>
                ) : (
                  <div style={{ position: "relative", margin: "20px  10px 10px 10px" }} >
                    <div style={{ textAlign: "right" }}>
                      <p className="chat__msg chat__msg--sent position-relative" ref={assignRef()}>
                        <span id={`id_${msgIndex}`}>{message.content}</span>
                        <span className="chat__msg-filler"> </span>
                        <span className="chat__msg-footer">
                          <span> {formatTime(message.time)} </span>
                          <Icon
                            id={
                              message?.status === "sent"
                                ? "singleTick"
                                : "dobuleTickWhite"
                            }
                            aria-label={message?.status}
                            className={`chat__msg-status-icon ${message?.status === "read"
                              ? "chat__msg-status-icon--blue"
                              : ""
                              }`}
                          />
                        </span>
                        <div class="iconWrapper">
                          <button
                            onMouseEnter={() => setShowTooltip(msgIndex)}
                            onMouseLeave={() => setShowTooltip(null)}
                            onClick={() => copyToClipboard(message.content, msgIndex)}
                            aria-label="Copy message"
                            style={{ marginRight: "10px" }}
                          >
                            <div class="hover-text"><Icon id="copy" />
                              {agencyInfo?.Customization?.copyicon_text &&
                                <span class="tooltip-text" id="copytext">{agencyInfo?.Customization?.copyicon_text}</span>}
                            </div>
                          </button>
                          <button aria-label="Copy message" onClick={() => { handleMemory(message?.content) }}>
                            <div class="hover-text">
                              {loading ? (
                                <Icon id="spinner" />
                              ) : (
                                <Icon id="brainai" />
                              )}
                              <span class="tooltip-text" id="braintext">
                                {agencyInfo?.Customization?.brainicon_text}
                              </span>
                            </div>
                          </button>
                        </div>
                      </p>
                      <div style={{ margin: '20px 0' }}></div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {activeConvo && activeConvo?.typing ? (
            <div class="my message " id="gotovie" style={{ marginTop: "30px" }}>

              <span class="jumping-dots">
                <span class="dot-1"></span>
                <span class="dot-2"></span>
                <span class="dot-3"></span>
              </span>
            </div>
          ) : (
            ""
          )}


        </div>

      </div>

    );
  });
};

export default Convo;
