import React, { useContext, useState } from "react";
import "./styles/main.css";
import { useUsersContext } from "context/usersContext";
import ChatModal from "./components/ChatModal";
import { useLocation } from "react-router-dom";
import Prompts from "pages/Chat/components/Prompts";
import ChatAccordions from "pages/Chat/components/ChatAccordions";
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import FavChatAccordions from "pages/Chat/components/FavChatAccordions";
import SharedChatAccordions from "pages/Chat/components/SharedChatAccordions";
import FavPromptAccordions from "pages/Chat/components/FavPromptAccordions";
import AddChatCategoryModal from "components/AddChatCategoryModal";
import SharedPromptAccordions from "pages/Chat/components/SharedPromptAccordions";
import UnCatPromptAccordions from "pages/Chat/components/UnCatPromptAccordions";

const Sidebar = ({ setIsSettingsPage }) => {
  const { users: contacts, locValid, isActiveSide, handleDeleteContact, processing, showExploreContent, enableExplore, enableChat, SSO, agencyConfig } = useUsersContext();
  const { promptsData, getPrompts, favouritesData, getFavourites, chatCategories, getChatCategories } = useContext(PromptsContext);
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const [isOpenAddChatCategoryModal, setIsOpenAddChatCategoryModal] = useState(false);
  const [contactID, setContactID] = useState();

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }
  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }

  const addChatCategoryHandle = (Contactid) => {
    setContactID(Contactid)
    setIsOpenAddChatCategoryModal(true);
  }

  const justCloseAddChatCategoryModal = () => {
    setIsOpenAddChatCategoryModal(false);
  }

  const { pathname } = useLocation();
  const userId = pathname.split("/")[2];
  const favChatIds = favouritesData?.map(fav => fav.chat_id);
  const favPromptIds = favouritesData?.map(fav => fav.prompt_id);

  const favChats = contacts?.filter(chat => favChatIds?.includes(chat.id));
  const favPrompts = promptsData?.prompt?.filter(prompt => favPromptIds?.includes(prompt._id));

  const favourites = [{ name: 'Favourites' }]
  const Shared = [{ name: 'Shared with you' }]
  const uncategorisedPrompt = [{ name: 'Uncategorised' }]

  return (
    <>
      {locValid ? (
        <aside className={`sidebar ${!isActiveSide ? 'dNone' : ''}`}>
          <header className="header">
            <ChatModal processing={processing} enableExplore={enableExplore} enableChat={enableChat} showExploreContent={showExploreContent} setIsSettingsPage={setIsSettingsPage} chatId={userId} />
          </header>
          {showExploreContent ? (
            <div className="sidebar__explore-content">

              <div className="sidebarWrapper">

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '10px' }}>
                  <h2 className="promptsHeading" style={{ margin: 0 }}>General Prompts</h2>
                </div>

                <Prompts userId={userId} promptsData={promptsData} type="general" favourites={favouritesData} getFavourites={getFavourites} agencyConfig={agencyConfig} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '10px' }}>
                  <h2 className="promptsHeading" style={{ margin: 0 }}>Your Prompts</h2>
                  <p style={{ margin: '0 6px 0 0', cursor: 'pointer', textDecoration: 'underline' }} onClick={addPromptHandle}>+ Add Prompt</p>
                </div>
                <FavPromptAccordions promptsData={promptsData} categories={favourites} contacts={favPrompts} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />
                <SharedPromptAccordions promptsData={promptsData} categories={Shared} contacts={promptsData?.prompt?.filter(item => {
                  return item.userId.some(user => user.id === SSO?.userUUID && user.type === "shared");
                })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                <UnCatPromptAccordions promptsData={promptsData} categories={uncategorisedPrompt} contacts={promptsData?.prompt?.filter(item => item.category === '' && item.sub_category === '').filter(item => item.locationId === SSO?.id).filter(item => {
                  if (item.category === '' && item.sub_category === '') {
                    if (item.set_as === 'personal') {
                      return item.userId.some(user => user.id === SSO?.userUUID);
                    }
                    return true;
                  }
                  return false;
                })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                <Prompts userId={userId} promptsData={promptsData} type="personal" favourites={favouritesData} getFavourites={getFavourites} refreshPrompts={getPrompts} />

              </div>
            </div>

          ) : (
            <div className="sidebar__contacts">

              {processing ? (
                <div className="chatSpinner"></div>
              ) : (
                <div className="sidebarWrapper">
                  <FavChatAccordions addChatCategoryHandle={addChatCategoryHandle} chatCategories={chatCategories} categories={favourites} contacts={favChats} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                  <SharedChatAccordions addChatCategoryHandle={addChatCategoryHandle} chatCategories={chatCategories} categories={Shared} contacts={contacts.filter(item => {
                    return item.userId.some(user => user.id === SSO?.userUUID && user.type === "shared");
                  })} onDelete={handleDeleteContact} favourites={favouritesData} getFavourites={getFavourites} />

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px', marginTop: '10px' }}>
                    <h2 className="promptsHeading" style={{ margin: 0 }}>Your Chats</h2>
                    <p style={{ margin: '0 6px 0 0', cursor: 'pointer', textDecoration: 'underline' }} onClick={addChatCategoryHandle}>+ Add Category</p>
                  </div>

                  <ChatAccordions addChatCategoryHandle={addChatCategoryHandle}
                    categories={chatCategories}
                    contacts={contacts.filter(item => {
                      return item.userId.some(user => user.id === SSO?.userUUID && user.type === "author");
                    })} onDelete={handleDeleteContact}
                    favourites={favouritesData}
                    getFavourites={getFavourites}
                    handleRefresh={getChatCategories} />

                </div>
              )}
            </div>
          )}
          <AddPromptModal
            isModalOpen={isOpenAddPromptModal}
            categories={promptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
            subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
            justCloseIt={justCloseIt}
            getUpdatePromptsData={getPrompts}

          />
          <AddChatCategoryModal
            isModalOpen={isOpenAddChatCategoryModal}
            Oprationcontact={contactID}
            justCloseIt={justCloseAddChatCategoryModal}
            refreshChatCategories={getChatCategories}
          />
        </aside>
      ) : (
        <></>
      )}
    </>
  );
};

export default Sidebar;
