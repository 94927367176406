
import axios from "axios";
import env from "config";
import {
  useState
} from "react";

const SsoHandler = () => {
  const BASE_URL = `${env.API_URL}/v1`;
  const [ssodata, setssodata] = useState("");

  const checkSSO = async (app) => {
    const searchParams = new URLSearchParams(document.location.search);
    const activepayload = searchParams.get("activepayload");
    
    // Parse the activepayload
    let parsedObject = JSON.parse(activepayload);
    console.log('activepayload', parsedObject?.id);
    
    if (parsedObject && parsedObject?.id) {
      const locationData = await axios
        .get(`${BASE_URL}/accounts/location?id=${parsedObject?.id}`)
        .then(function (response) {
          console.log('response', response);
          return response?.data?.data;
        })
        .catch(function (error) {
          console.error('Error fetching location data:', error);
          return null;
        });
  
      if (locationData) {
        parsedObject = {
          ...parsedObject,
          ...locationData 
        };
      }
    }
  
    setssodata(parsedObject);
  };
  
  return {
    SSO: ssodata,
    checkSSO: checkSSO,
  };
};
export default SsoHandler;