import React, { useEffect } from 'react';

const ContentRenderer = ({ index,htmlContent }) => {
  useEffect(() => {
    // Function to handle anchor tag clicks
    const handleAnchorClick = (event) => {
      event.preventDefault();
      const url = event.target.href;
      // Open the URL in a new tab
      window.open(url, '_blank');
    };

    // Get all anchor tags within the rendered HTML
    const anchors = document.querySelectorAll('.markDownSetting a');

    // Add event listeners to each anchor tag
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    // Cleanup event listeners on component unmount
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [htmlContent]); // Re-run the effect if htmlContent changes

  return (
    <span id={`id_${index}`} className="markDownSetting">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </span>
  );
};

export default ContentRenderer;
