import React, { useState } from "react";
import Icon from "components/Icon";
import "./index.css";
import localforage from "localforage";
import axios from "axios";
import env from "config";

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
// Example usage in another component
const SharePromptModal = ({
  isModalOpen,
  justCloseIt,
  contact,
  getLocationUsers,
  locationUsers,
}) => {
  const [proceedToShare, setProceedToShare] = useState(false);
  const [shareToUser, setShareToUser] = useState({});
  const [shareToPermission, setShareToPermission] = useState("use-only");
  const [processing, setProcessing] = useState(false);
  const [shareError, setShareError] = useState('');
  const BASE_URL = `${env.API_URL}/v1`;

  const handleProceedToShare = () => {
    setProceedToShare(true);
  };

  const handleClose = () => {
    justCloseIt();
    setProceedToShare(false);
    setShareError(false);
  };

  const handleShareSubmit = async () => {
    if (!shareToUser?.userName) {
      setShareError(true);
      return false;
    }
    setProcessing(true);
    const payload = {
      _id: contact._id,
      userId: [
        {
          id: shareToUser?._id,
          // id: shareToUser?.userId,
          username: shareToUser?.userName,
          type: "shared",
          permission: shareToPermission,
        },
        ...contact?.userId,
      ],
    };
    return await axios
      .put(BASE_URL + "/prompts", payload)
      .then(async function (response) {
        handleClose();
        setProcessing(false);
        setShareError(false);
      })
      .catch(function (error) {
        console.log(error);
        setProcessing(false);
      });
  };

  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          {proceedToShare ? (
            <div style={{ position: "relative" }}>
              <p
                style={{
                  position: "absolute",
                  right: 0,
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <Icon id="close" />
              </p>
              <p className="modalText">Share '{contact?.title}'</p>
              <div className="formGroup" style={{ marginBottom: "30px" }}>
                <label>Add People</label>
                <div style={{ display: "flex", alignItems: 'baseline', gap: "18px", justifyContent: 'space-between' }}>
                  <div>
                    <select
                      className="flex-1 modalInputs"
                      style={{ textTransform: "capitalize", width: '100%' }}
                      onChange={(e) => setShareToUser(JSON.parse(e.target.value))}
                    >
                      <option selected disabled>
                        Select User
                      </option>
                      {locationUsers?.map((user) => (
                        <option value={JSON.stringify(user)}>
                          {user?.userName}
                        </option>
                      ))}
                    </select>
                    <small style={{ color: 'red', visibility: shareError ? 'visible' : 'hidden' }}>Please select a user to share with!</small>
                  </div>
                  <select
                    onChange={(e) => setShareToPermission(e.target.value)}
                    style={{ margin: 0 }}
                  >
                    <option value={"use-only"}>Use-only</option>
                    <option value={"editor"}>Editor</option>
                  </select>
                </div>
              </div>
              {contact?.userId?.length > 0 && (
                <div className="formGroup">
                  <label>People with access</label>
                  {contact?.userId?.map((user) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "18px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            flexShrink: 0,
                            borderRadius: "100px",
                            background: getRandomColor(),
                            marginRight: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontFamily: "'Raleway',sans-sarif",
                              fontWeight: 700,
                              fontSize: "14px",
                              textAlign: "center",
                              color: "#fff",
                              lineHeight: "40px",
                              textTransform: "uppercase",
                            }}
                          >
                            {user?.username?.split("")[0]}
                          </p>
                        </div>
                        <p style={{ margin: 0 }}>
                          {user?.username}{" "}
                          {user?.type === "author" ? "(You)" : ""}
                        </p>
                      </div>
                      <select style={{ border: "none", outline: "none" }} value={user?.type === 'author' ? 'Editor' : user?.type}>
                        <option value={'Use-only'}>Use-only</option>
                        <option value={'Editor'}>Editor</option>
                      </select>
                    </div>
                  ))}
                </div>
              )}
              <hr style={{ borderColor: "#92A5AF" }} />
              {contact?.userId?.length > 0 && (
                <div className="formGroup" style={{ marginBottom: "30px" }}>
                  <label>General access</label>
                  {contact?.userId?.filter((user) => { return user.type === 'author' })?.map((user) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "18px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            flexShrink: 0,
                            borderRadius: "100px",
                            background: getRandomColor(),
                            marginRight: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              fontFamily: "'Raleway',sans-sarif",
                              fontWeight: 700,
                              fontSize: "14px",
                              textAlign: "center",
                              color: "#fff",
                              lineHeight: "40px",
                            }}
                          >
                            {user?.username?.split("")[0]}
                          </p>
                        </div>
                        <div>
                          <p style={{ margin: 0 }}>
                            {user?.username}{" "}
                            {user?.type === "author" ? "(You)" : ""}
                          </p>
                          <small
                            style={{
                              color: "var(--secondary-tint-1, #92A5AF)",
                              fontFamily: "Raleway, sans-sarif",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            Anyone in this group can use this prompt
                          </small>
                        </div>
                      </div>
                      <select style={{ border: "none", outline: "none" }} value={user?.type === 'author' ? 'Editor' : user?.type}>
                        <option value={'Use-only'}>Use-only</option>
                        <option value={'Editor'}>Editor</option>
                      </select>
                    </div>
                  ))}
                </div>
              )}
              <p
                className="disagreeBtn"
                style={{
                  margin: 0,
                  width: "100%",
                  display: "block",
                  opacity: processing ? 0.6 : 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                disabled={processing}
                onClick={handleShareSubmit}
              >
                {processing && <div className="spinner"></div>}
                Done
              </p>
            </div>
          ) : (
            <>
              <p className="modalText">Confirm access change</p>
              <p className="modalDesc">
                Are you sure you want to change the prompt access to shared?
              </p>
              <div className="modalBtnGroup">
                <button
                  type="submit"
                  className="okBtn"
                  onClick={handleProceedToShare}
                >
                  Ok
                </button>
                <p className="disagreeBtn" onClick={handleClose}>
                  Cancel
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SharePromptModal;
